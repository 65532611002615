import { ThemeService } from './../services/bootstrap/theme.service';
import { APP_INITIALIZER } from '@angular/core';
import { ConfigurationService } from '../services/config/configuration.service';
import { TranslateLangService } from '../services/bootstrap/translate-lang.service';

export function TranslateLangServiceFactory(translateLangService: TranslateLangService) {
  return () => translateLangService.load();
}

export function ConfigurationProviderFactory(configService: ConfigurationService): any {
  return () => configService.initParse();
}

export function ThemeServiceFactory(themeService: ThemeService): any {
  return () => themeService.getTheme();
}

export const appInitializerProviders = [
  {
    provide: APP_INITIALIZER,
    useFactory: TranslateLangServiceFactory,
    deps: [TranslateLangService],
    multi: true,
  }
  ,
  {
    provide: APP_INITIALIZER,
    useFactory: ConfigurationProviderFactory,
    multi: true,
    deps: [ConfigurationService]
  }
  ,
  {
    provide: APP_INITIALIZER,
    useFactory: ThemeServiceFactory,
    multi: true,
    deps: [ThemeService]
  }
];

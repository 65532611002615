import { of, Subscription } from 'rxjs';

import { Mongo } from '@shared/data/mongo';
import { AnimationsService } from '@core/services/animation/animations.service';
import { Component, HostBinding, OnInit, ViewEncapsulation, AfterContentInit, NgZone } from '@angular/core';
import { routeAnimations, ROUTE_ANIMATIONS_ELEMENTS } from '@core/animations/route.animations';
import { FundoService } from '@core/services/bootstrap/fundo.service';
import { Router } from '@angular/router';
import { AuthGuard } from '@core/guard/auth.guard';
import { ThemeService } from '@core/services/bootstrap';

@Component({
  selector: 'app-auth',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [routeAnimations]
})
export class ContainerComponent implements OnInit, AfterContentInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  @HostBinding('class') theme: string; fundoAtual: string;
  subFundo: Subscription;

  constructor(
    private mongo: Mongo,
    private ngZone: NgZone,
    private router: Router,
    private authGuard: AuthGuard,
    private fundoService: FundoService,
    private themeService: ThemeService,
    private animationsService: AnimationsService
  ) {
    this.authGuard.authenticated();
  }

  ngOnInit(): void {
    this.themeService.getTheme();
    this.animationsService.updateRouteAnimationType(false, true);
    this.themeService.themeObservab.subscribe((theme) => theme ? this.theme = theme : this.theme = 'tm_default');
  }

  ngAfterContentInit(): void {
    this.subFundo = this.fundoService.fundoObserv.subscribe((fundoAtual) => {
      this.fundoAtual = fundoAtual;
      // console.log('FundoAtual', fundoAtual);
    });
  }

}

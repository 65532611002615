import { NgModule } from '@angular/core';
import { LanguageComponent } from './language.component';

import { AngsModule } from 'src/app/shared/angs.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [LanguageComponent],
  imports: [
    AngsModule,
    MatIconModule, MatButtonModule, MatMenuModule
  ],
  exports: [LanguageComponent]
})
export class LanguageModule { }


import { NgModule } from '@angular/core';
import { AngsModule } from '@shared/angs.module';
import { ThemeComponent } from './theme.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { ThemeService } from '@core/services/bootstrap';

@NgModule({
  declarations: [ThemeComponent],
  imports: [
    AngsModule, TranslateModule,

    MatIconModule, MatButtonModule, MatMenuModule
  ],
  exports: [ThemeComponent],
  providers: [ThemeService]
})
export class ThemeModule { }

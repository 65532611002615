import { Subscription } from 'rxjs';
import { ThemeService } from '@core/services/bootstrap/theme.service';
import { Component, OnInit, AfterContentInit, OnDestroy } from '@angular/core';
import { routeAnimations, ROUTE_ANIMATIONS_ELEMENTS } from 'src/app/core/animations/route.animations';
import { Theme } from '@core/models/theme.model';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss'],
  animations: [routeAnimations]
})
export class ThemeComponent implements OnInit, AfterContentInit, OnDestroy {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  theme: string; subDark: Subscription;
  itemsTheme: Theme[] = [];
  subTema: Subscription;

  constructor(
    private themeService: ThemeService
    )
    {
      this.subTema = this.themeService.listThemes().subscribe((items: Theme[]) => {
        this.itemsTheme = items;
      });
    }

  ngOnInit(): void {
    this.subDark = this.themeService.themeObservab.subscribe((theme) => {
      if (theme) {
        this.theme = theme;
      } else {
        this.theme = 'tm_default';
      }
    });
  }

  ngAfterContentInit(): void {
    this.themeService.getTheme();
  }

  ngOnDestroy(): void {
    if (this.subDark) {
      this.subDark.unsubscribe();
    }
  }

  onClickTheme(theme: string, dark: boolean) {
    this.themeService.applyTheme(theme, dark);
    this.themeService.storeTheme(theme, dark);
    this.themeService.listThemes().subscribe((items: Theme[]) => {
      this.itemsTheme = items;
    });
  }
}

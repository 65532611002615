export const MENU_ITENS =
{
  menuPublic:
  [
    {
      route: 'public',
      name: 'public_area',
      type: 'link',
      icon: 'directions',
      badge: {
        color: 'green-500',
        value: '4'
      }
    },
    {
      route: 'sessions',
      name: 'sessions',
      type: 'sub',
      icon: 'question_answer',
      children: [{
          route: '403',
          name: '403',
          type: 'link'
        },
        {
          route: '404',
          name: '404',
          type: 'link'
        },
        {
          route: '500',
          name: '500',
          type: 'link'
        }
      ]
    },
    {
      route: 'menu-level',
      name: 'menu-level',
      type: 'sub',
      icon: 'subject',
      label: {
        color: 'indigo-500',
        value: 'new'
      },
      children: [
        {
          route: 'level-1-1',
          name: 'level-1-1',
          type: 'sub',
          children: [
            {
              route: 'level-2-1',
              name: 'level-2-1',
              type: 'sub',
              children: [
                {
                  route: 'level-3-1',
                  name: 'level-3-1',
                  type: 'sub',
                  children: [
                    {
                      route: 'level-4-1',
                      name: 'level-4-1',
                      type: 'link'
                    }
                  ]
                }
              ]
            },
            {
              route: 'level-2-2',
              name: 'level-2-2',
              type: 'link'
            }
          ]
        },
        {
          route: 'level-1-2',
          name: 'level-1-2',
          type: 'link'
        }
      ]
    }
  ],
  menuHome:
  [
    {
      route: '/admin',
      name: 'admin',
      type: 'link',
      icon: 'admin_panel_settings',
      // badge: {
      //   color: 'red-500',
      //   value: '5'
      // },
      visible: true
    },
    {
      route: '/home/dashboard',
      name: 'dashboard',
      type: 'link',
      icon: 'dashboard',
      // badge: {
      //   color: 'red-500',
      //   value: '5'
      // },
      visible: true
    },
    {
      route: '/home/membros',
      name: 'membros',
      type: 'link',
      icon: 'people',
      visible: true
    },
    {
      route: '/home/relatorios',
      name: 'relatorios',
      type: 'link',
      icon: 'print',
      visible: true
    },
    {
      route: '/home/aniversarios',
      name: 'aniversarios',
      type: 'link',
      icon: 'cake',
      visible: false
    },
    {
      route: '/home/cartoes',
      name: 'cartoes',
      type: 'link',
      icon: 'card_giftcard',
      visible: false
    },
    {
      route: '/print',
      name: 'print',
      type: 'link',
      icon: 'print',
      visible: true
    },
    {
      route: 'sessions',
      name: 'sessions',
      type: 'sub',
      icon: 'question_answer',
      visible: true,
      children: [
        {
          route: '403',
          name: '403',
          type: 'link'
        },
        {
          route: '404',
          name: '404',
          type: 'link'
        },
        {
          route: '500',
          name: '500',
          type: 'link'
        }
      ]
    },
    {
      route: 'menu-level',
      name: 'menu-level',
      type: 'sub',
      icon: 'subject',
      visible: true,
      label: {
        color: 'indigo-500',
        value: 'new'
      },
      children: [
        {
          route: 'level-1-1',
          name: 'level-1-1',
          type: 'sub',
          children: [
            {
              route: 'level-2-1',
              name: 'level-2-1',
              type: 'sub',
              children: [
                {
                  route: 'level-3-1',
                  name: 'level-3-1',
                  type: 'sub',
                  children: [
                    {
                      route: 'level-4-1',
                      name: 'level-4-1',
                      type: 'link'
                    }
                  ]
                }
              ]
            },
            {
              route: 'level-2-2',
              name: 'level-2-2',
              type: 'link'
            }
          ]
        },
        {
          route: 'level-1-2',
          name: 'level-1-2',
          type: 'link'
        }
      ]
    }
  ],
  menuAdmin:
  [
    {
      route: '/admin/import',
      name: 'dashboard',
      type: 'link',
      icon: 'dashboard',
      badge: {
        color: 'blue-500',
        value: '5'
      }
    }
  ]
};

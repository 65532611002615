import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { appInitializerProviders } from './core/providers/initializers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPermissionsModule } from 'ngx-permissions';

// Required for AOT compilation
export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const modulos =
[
  HttpClientModule,
  BrowserAnimationsModule,
  // NgxMaskModule.forRoot(),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: TranslateHttpLoaderFactory,
      deps: [HttpClient],
    },
  }),

  ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  NgxPermissionsModule.forRoot()
];

@NgModule({
  declarations: [],
  imports: [
    modulos
  ],
  exports: [modulos],
  providers:
  [
    appInitializerProviders
  ]
})
export class AppSharedModule {}

import { Injectable } from '@angular/core';
import { Theme } from '@core/models/theme.model';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private themeSubject: Subject<string> = new BehaviorSubject<string>(undefined);
  themeObservab = this.themeSubject.asObservable();
  constructor(private overlayContainer: OverlayContainer){}

  listThemes(): Observable<Theme[]> {
    const listaTemas: Theme[] = [
    {name: 'tm_default',  color: '#0d47a1', dark: false, selected: false, active: true},
    {name: 'tm_abhoba',   color: '#7f6000', dark: false, selected: false, active: true},
    {name: 'tm_marrom',   color: '#795548', dark: false, selected: false, active: true},
    {name: 'tm_rosa',     color: '#7b1fa2', dark: false, selected: false, active: true},
    {name: 'tm_verde',    color: '#004D40', dark: false, selected: false, active: true},
    {name: 'tm_wine',     color: '#421111', dark: false, selected: false, active: true},
    ];
    const darkAtual = localStorage.getItem('dark');
    const themeAtual = localStorage.getItem('theme');
    listaTemas.forEach((teminha) => {
      const obJota = listaTemas.find(x => x.name === teminha.name);
      if (themeAtual === teminha.name) {
        obJota.selected = true;
      } else {
        obJota.selected = false;
      }
      if (darkAtual === 'true') {
        obJota.dark = true;
      } else {
        obJota.dark = false;
      }
    });
    return of(listaTemas);
  }

  applyTheme(theme: string, isDark: boolean) {
    let themeTemp = 'tm_default';
    let isDarkTemp = false;
    if (theme !== null && theme !== undefined) {
      themeTemp = theme;
    }
    if (isDark !== null && isDark !== undefined) {
      isDarkTemp = isDark;
    }
    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
    const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) => item.includes('tm_'));
    if (themeClassesToRemove.length) {
        overlayContainerClasses.remove(...themeClassesToRemove);
    }

    if (isDarkTemp) {
      this.overlayContainer.getContainerElement().classList.add(themeTemp + 'Dark');
    } else {
      const darkClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) => item.includes('Dark'));
      if (darkClassesToRemove.length) {
          overlayContainerClasses.remove(...darkClassesToRemove);
      }
      this.overlayContainer.getContainerElement().classList.add(themeTemp);
    }
    if (isDarkTemp) {
      this.themeSubject.next(themeTemp + 'Dark');
    } else {
      this.themeSubject.next(themeTemp);
    }
  }

  storeTheme(theme: string, dark: boolean) {
    if (theme) {
      localStorage.setItem('theme', theme);
    } else {
      localStorage.setItem('theme', 'tm_default');
    }
    if (dark) {
      localStorage.setItem('dark', dark.toString());
    } else {
      localStorage.setItem('dark', 'false');
    }
  }

  getTheme() {
    let theme: string;
    let dark: boolean;
    if (localStorage.getItem('theme')) {
      theme = localStorage.getItem('theme');
    } else {
      theme = 'tm_default';
    }

    if (localStorage.getItem('dark')) {
      const darkStorage = localStorage.getItem('dark');
      if (darkStorage === 'true') { dark = true; }
      else {dark = false; }}
      else {dark = false; }
    this.applyTheme(theme, dark);
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  parse: any;

  async initParse(): Promise<void> {
    // ParseObj.initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
    // ParseObj.serverURL = environment.serverURL;
  // ParseObj.liveQueryServerURL = environment.liveQueryServerURL;
  }
}


import { Component } from '@angular/core';

@Component({
  selector: 'app-noite',
  templateUrl: './noite.component.html',
  styleUrls: ['./noite.component.scss']
})
export class NoiteComponent {

}

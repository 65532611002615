import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GroupByPipe } from '../core/pipes/GroupBy.pipe';
import { HttpClientModule } from '@angular/common/http';

const modulos = [
  RouterModule, FormsModule, CommonModule, ReactiveFormsModule, FlexLayoutModule,
  HttpClientModule
];

const pipes = [
  GroupByPipe
];

@NgModule({
  declarations: [pipes],
  exports: [
    modulos, pipes
  ]
})
export class AngsModule { }

import { NgModule } from '@angular/core';
import { Mongo } from '@shared/data/mongo';
import { AppComponent } from './app.component';
import { AppSharedModule } from './app-shared.module';
import { AppRoutingModule } from './app-routing.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AnimationsService } from './core/services/animation/animations.service';
import { PublicModule } from './stack/public/public.module';


@NgModule({
  declarations: [AppComponent],
  imports: [
    AppSharedModule,
    AppRoutingModule,
    PublicModule
  ],
  providers: [
    Mongo,
    AnimationsService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'}
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

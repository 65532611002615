import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
} from '@angular/core';

export interface routesTemp {
  name: string,
  route: string
}

@Component({
  selector: 'app-header-auth',
  templateUrl: './header-auth.component.html',
  styleUrls: ['./header-auth.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderAuthComponent implements OnInit {
  @Input() showToggle = true;
  @Input() showBranding = false;

  @Output() toggleSidenav = new EventEmitter<void>();
  @Output() toggleSidenavNotice = new EventEmitter<void>();
 
  routesTemp: routesTemp[] = [
    {name: 'Destaques', route: 'featured'},
    {name: 'Notícias', route: 'news'},
    {name: 'Canais', route: 'channels'},
    {name: 'Humor', route: 'humor'}
  ]

  constructor() { }

  ngOnInit(): void {}

}

import { Inject, Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarRef, MatSnackBarVerticalPosition, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarData } from '@models';
import { SnackComponent } from '@shared/components/snack/snack.component';

@Injectable({
  providedIn: 'root'
})
export class SnackService {

  private snackBarRef: MatSnackBarRef<SnackComponent>;
  private msgQueue = [];
  private isInstanceVisible = false;

  constructor(
    public zone: NgZone,
    public snackBar: MatSnackBar,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData
  ) { }

    /**
     * Add a message
     * @param message The message to show in the snackbar.
     * @param img Can be an image, like user avatar or an icon. Depending on type.
     * @param action The label for the snackbar action.
     * @param duration The duration fo the snackbar.
     * @param vPosition Vertical Position of the snackbar.
     * @param hPosition Horizontal Position fo the snackbar.
     * @param type Type for the snackbar: [toast-success, toast-info, toast-warning, toast-error].
     */
  addSnack(
    message: string, img: string, action: string, duration: number,
    vPosition: MatSnackBarVerticalPosition,
    hPosition: MatSnackBarHorizontalPosition,
    type: string) {
      const snackb: SnackBarData =
      {
        message,
        img,
        action,
        duration,
        vPosition,
        hPosition,
        type
      };
      this.msgQueue.push(snackb);
      if (!this.isInstanceVisible) {
          this.showNext();
      }
  }

  showNext() {
    if (this.msgQueue.length === 0) {
      return;
    }
    const snackb: SnackBarData = this.msgQueue.shift();
    this.isInstanceVisible = true;
    this.zone.run(() => {
      this.snackBarRef = this.snackBar.openFromComponent(SnackComponent,
        {
          data: snackb,
          duration: snackb.duration,
          verticalPosition: snackb.vPosition,
          horizontalPosition: snackb.hPosition,
          panelClass: ['snack', snackb.type]
        });
      this.snackBarRef.afterDismissed().subscribe(() => {
        this.isInstanceVisible = false;
        this.showNext();
      });
    });
  }

  // openSnackBar(
  //   mensagem: string, imagem: string, acao: string, duration: number,
  //   vertPosition: MatSnackBarVerticalPosition,
  //   horizontPosition: MatSnackBarHorizontalPosition,
  //   tipo: string) {
  //     this.zone.run(() => {
  //       this.snackBar.openFromComponent(SnackComponent,
  //         {
  //           data: {mensagem, imagem,  acao, tipo},
  //           duration,
  //           verticalPosition: vertPosition,
  //           horizontalPosition: horizontPosition,
  //           panelClass: ['snack', tipo]
  //         });
  //     });
  // }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-madrugada',
  templateUrl: './madrugada.component.html',
  styleUrls: ['./madrugada.component.scss']
})
export class MadrugadaComponent implements OnInit {

  arrayOne(n: number): any[] {
    return Array(n);
  }
  constructor() { }

  ngOnInit() {
  }

}

import { NgModule } from '@angular/core';
import { AngsModule } from '@shared/angs.module';
import { TranslateModule } from '@ngx-translate/core';

import { SearchButtonComponent } from './search-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [SearchButtonComponent],
  imports: [
    AngsModule, TranslateModule,

    MatIconModule, MatButtonModule
  ],
  exports: [SearchButtonComponent]
})
export class SearchButtonModule { }

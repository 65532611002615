import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manha',
  templateUrl: './manha.component.html',
  styleUrls: ['./manha.component.scss']
})
export class ManhaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Observable, of } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Mongo } from '@shared/data/mongo';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private mongo: Mongo,
    private ngZone: NgZone,
    private router: Router
  ){}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.mongo.App.currentUser && this.mongo.App.currentUser.isLoggedIn) {
      return true;
    } else {
      this.ngZone.run(async () => { await this.router.navigate(['/auth']); });
      return false;
    }
  }

  authenticated(): void {
    of(!!this.mongo.App.currentUser).pipe().subscribe((auth: boolean) => {
      if (auth) {
        this.router.navigate(['/home']);
      }
    });
  }
}

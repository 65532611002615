import { Component, OnInit, ChangeDetectionStrategy, HostBinding, ElementRef } from '@angular/core';

@Component({
  selector: 'app-search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchButtonComponent implements OnInit {
  @HostBinding('class') theme: 'tm_default';
  constructor(private elRef: ElementRef) { }

  ngOnInit(): void {}

  on() {
    document.getElementById('searchOverlayy').classList.add('showOverlayy');
  }

}


<div [ngSwitch]="data.type" class="mat-typography">

  <div *ngSwitchDefault fxLayout="row" fxLayoutAlign="start center" >
    <mat-icon>{{data.img}}</mat-icon>
    &nbsp;
    <div fxLayout="row" fxLayoutAlign="start center">
      <strong>{{data.message}}</strong>
    </div>
    <span fxFlex *ngIf="data.action"></span>
    <button mat-button (click)=dismiss() *ngIf="data.action">
      <strong>{{data.action}}</strong>
    </button>
  </div>

  <div *ngSwitchCase="'toast-custom'" fxLayout="row" fxLayoutAlign="start center">
    <img mat-card-avatar [src]=data.img>     
    &nbsp;
    <div fxLayout="row" fxLayoutAlign="start center">
      <strong>{{data.message}}</strong>
    </div>
    <span fxFlex *ngIf="data.action"></span>
    <button mat-button (click)=dismiss() *ngIf="data.action">
      <strong>{{data.action}}</strong>
    </button>
  </div>

</div>


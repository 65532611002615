import * as Realm from 'realm-web';

export class Mongo {
  App: Realm.App = new Realm.App({ id: 'mamadubu-rzuva' });
  AppConfig = {
    id: this.App.id,
    timeout: 1000,
  };
  Credentials = Realm.Credentials;
  AnonCredentials = Realm.Credentials.anonymous();
  User: Realm.User;
  Service = 'mongodb-atlas';
  DB = 'mamaDubu';
  Bson = Realm.BSON;
  ProviderType: Realm.ProviderType;

  objectIdString(object: any): string {
    return object._id.$oid;
  }

  async createAnonUser(): Promise<any> {
    const user: Realm.User = await this.App.logIn(this.AnonCredentials);
    return user;
  }

  collection(colecao: string) {
    const mongodb = this.App.currentUser.mongoClient(this.Service);
    return mongodb.db(this.DB).collection(colecao);
  }

  async createDocument(colecao: string, obj: object) {
    const mongodb = this.App.currentUser.mongoClient(this.Service);
    return await mongodb.db(this.DB).collection(colecao).insertOne(obj);
  }

  async findDocument(colecao: string, id: string) {
    const objectId = new this.Bson.ObjectID(id);
    const query = { _id: objectId };
    const mongodb = this.App.currentUser.mongoClient(this.Service);
    return await mongodb.db(this.DB).collection(colecao).findOne(query);
  }

  async updateDocument(colecao: string, object: any) {
    const objectId = new this.Bson.ObjectID(object._id);
    delete(object._id);
    const query = { _id: objectId };
    const mongodb = this.App.currentUser.mongoClient(this.Service);
    return await mongodb.db(this.DB).collection(colecao).updateOne(query, { $set : object }, { upsert: true });
  }

  async deleteDocument(colecao: string, id: string) {
    const mongodb = this.App.currentUser.mongoClient(this.Service);
    const objectId = new this.Bson.ObjectID(id);
    return await mongodb.db(this.DB).collection(colecao).deleteOne({_id: objectId});
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerComponent } from 'src/app/layout/container/container.component';

const routes: Routes = [
  {
    path: '', component: ContainerComponent, data: { preload: true, delay: true, state: 'auth' },
    children:
    [
      { path: '', redirectTo: 'intro', pathMatch: 'full' },
      {
        path: 'intro',
        loadChildren: () => import('../../routes/intro/intro.module').then(m => m.IntroModule),
        data: { title: 'Início', titleI18n: 'intro' },
      },
      {
        path: 'news',
        loadChildren: () => import('../../routes/news/news.module').then(m => m.NewsModule),
        data: { title: 'News', titleI18n: 'news' }
      },
      {
        path: 'articles',
        loadChildren: () => import('../../routes/articles/articles.module').then(m => m.ArticlesModule),
        data: { title: 'Articles', titleI18n: 'articles' } },
      {
        path: 'videos',
        loadChildren: () => import('../../routes/videos/videos.module').then(m => m.VideosModule),
        data: { title: 'Vídeos', titleI18n: 'videos' }
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule { }

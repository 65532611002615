<div class="fundo">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" class="svgTop colorPrimary">
    <path fill-opacity="1" d="M0,64L80,96C160,128,320,192,480,229.3C640,267,800,277,960,261.3C1120,245,1280,203,1360,181.3L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
  </svg>
  
  
  <!-- <svg
  width="600"
  height="600"
  viewBox="0 0 600 600"
  xmlns="http://www.w3.org/2000/svg"
  class="svgBolha colorWarn"
>
  <g transform="translate(150,150)">
    <path d="M27.4,-70.4C39.9,-34.5,57.7,-34.5,79.8,-25C101.8,-15.6,128.1,3.3,134.3,25.3C140.5,47.3,126.5,72.4,110.7,102.3C94.8,132.2,77,166.9,48.7,183.1C20.4,199.3,-18.4,197,-29,164.1C-39.6,131.2,-22.1,67.5,-51.3,40.7C-80.6,13.8,-156.6,23.6,-192.8,6.1C-229.1,-11.5,-225.5,-56.4,-209.7,-97.8C-193.9,-139.1,-165.9,-176.8,-128.9,-201.6C-91.9,-226.3,-46,-238.2,-19.3,-208.2C7.4,-178.2,14.8,-106.3,27.4,-70.4Z"/>
  </g>
</svg> -->



  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" class="svgBottom colorPrimary">
    <path fill-opacity="1" d="M0,128L80,128C160,128,320,128,480,138.7C640,149,800,171,960,154.7C1120,139,1280,85,1360,58.7L1440,32L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
  </svg>
</div>


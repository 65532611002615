import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoiteComponent } from './noite.component';
@NgModule({
  declarations: [NoiteComponent],
  imports: [
    CommonModule, FlexLayoutModule
  ],
  exports:[NoiteComponent]
})
export class NoiteModule { }

<button mat-icon-button class="matero-toolbar-button" (click)="on()">
  <mat-icon>search</mat-icon>
</button>

<!-- 
https://codepen.io/AskYous/pen/WwZZgM?editors=1000
https://codepen.io/nikhil/pen/qcyGF
https://codepen.io/sdaitzman/pen/rmqfB
https://codepen.io/jkantner/pen/eYmvvqQ


COmo?
https://codepen.io/jakestuts/pen/xaLof

OUtro momento
https://codepen.io/AlbertFeynman/details/BPvzWZ
-->
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { AngsModule } from '@shared/angs.module';
import { TranslateModule } from '@ngx-translate/core';

import { BrandingModule } from '../branding/branding.module';
import { LanguageModule } from '../language/language.module';
import { ThemeModule } from '@shared/components/theme/theme.module';
import { HeaderAuthComponent } from './header-auth.component';
import { SearchButtonModule } from '@shared/components/search-button/search-button.module';
import { MatListModule } from '@angular/material/list';


@NgModule({
  declarations: [HeaderAuthComponent],
  imports: [
    AngsModule, TranslateModule,
    LanguageModule, BrandingModule,
    ThemeModule,

    SearchButtonModule,

    MatIconModule, MatToolbarModule, MatButtonModule, MatMenuModule, MatCardModule,
    MatListModule
  ],
  exports: [
    HeaderAuthComponent
  ]
})
export class HeaderAuthModule { }

import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FundoService implements OnDestroy {

  listaFundos: string[] = ['manha', 'tarde', 'noite', 'madrugada'];
  private fundoSubject = new BehaviorSubject<string>('manha');
  public fundoObserv = this.fundoSubject.asObservable();
  // myVar = setInterval(this.definirFundo, 10000);

  constructor() {this.checarHoraFundo(); }

  ngOnDestroy(): void {
    // clearInterval(this.myVar);
    // console.log('horInha', this.myVar);
  }

  getPosition(): Promise<any>
  {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });

  }

  checarHoraFundo() {
    const datInha = new Date();
    datInha.setTime(Date.now());
    const hora = datInha.getHours();
    // console.log('horInha', hora);
    if (hora >= 0 && hora <= 6){
      this.fundoSubject.next('madrugada');
    }else if (hora >= 7 && hora <= 12){
      this.fundoSubject.next('manha');
    }else if (hora >= 13 && hora <= 18){
      this.fundoSubject.next('tarde');
    }else if (hora >= 19 && hora <= 24){
      this.fundoSubject.next('noite');
    }


    // this.getPosition().then(pos=>
    //   {
    //      console.log(`Positon: ${pos.lng} ${pos.lat}`);
    //   });
  }

  definirFundo(): void {
    const date = new Date();
    date.setTime(Date.now());
    const hora = date.getHours();
    // console.log('horInha', hora);
  }

}

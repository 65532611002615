import { NgModule } from '@angular/core';
import { AngsModule } from '@shared/angs.module';
import { TranslateModule } from '@ngx-translate/core';
import { ContainerComponent } from './container.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NoiteModule } from './../../template/noite/noite.module';
import { TardeModule } from './../../template/tarde/tarde.module';
import { ManhaModule } from './../../template/manha/manha.module';
import { HeaderAuthModule } from '../header-auth/header-auth.module';
import { MadrugadaModule } from './../../template/madrugada/madrugada.module';
import { AnimationsService } from '@core/services/animation/animations.service';
import { SearchOverlayModule } from '../../shared/components/search-overlay/search-overlay.module';

const modules = [
  HeaderAuthModule,
  // EFEITOS
  ManhaModule, TardeModule, NoiteModule, MadrugadaModule,

  MatSidenavModule,

  SearchOverlayModule
];
@NgModule({
  declarations: [ContainerComponent],
  imports: [
    AngsModule, TranslateModule,
    modules
    ],
  exports: [modules],
  providers: [
    AnimationsService
  ]
})
export class ContainerModule { }

import { AfterViewInit, Component, HostBinding, OnInit } from '@angular/core';
import { PreloaderService } from './core/services/bootstrap/preloader.service';
import { AnimationsService } from './core/services/animation/animations.service';
import { routeAnimations, ROUTE_ANIMATIONS_ELEMENTS } from './core/animations/route.animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit, AfterViewInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;

  constructor(
    private preloader: PreloaderService,
    private animationsService: AnimationsService,
    ) {}

  ngOnInit(): void {
    this.animationsService.updateRouteAnimationType(true, true);
  }

  ngAfterViewInit() {
    this.preloader.hide();
  }
}

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgModule } from '@angular/core';
import { AngsModule } from '@shared/angs.module';
import { TranslateModule } from '@ngx-translate/core';

import { SearchOverlayComponent } from './search-overlay.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [SearchOverlayComponent],
  imports: [
    AngsModule, TranslateModule,

    MatIconModule, MatButtonModule, MatInputModule, MatFormFieldModule
  ],
  exports: [SearchOverlayComponent]
})
export class SearchOverlayModule { }

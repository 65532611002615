<div fxLayout="column" fxLayoutAlign="start stretch" fxFlexFill class="mat-typography">
  <div>
    <span fxFlex></span>
    <button mat-fab color="warn" class="closeButton" (click)="off()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form class="searchBar" fxLayout="column" fxLayoutAlign="start stretch">
    <mat-form-field class="my-form-field" style="margin: 0 !important; padding: 0 !important;" [ngClass]="routeAnimationsElements">
      <input
        matInput type="search" placeholder="{{'menu.search_for' | translate}}"
        [(ngModel)]="search" [ngModelOptions]="{standalone: true}"
      >
      <mat-icon mat-icon-button matSuffix *ngIf="search" aria-label="Clear" class="closeIcon" (click)="search=''">
        close
      </mat-icon>
    </mat-form-field>
  </form>
</div>
<mat-toolbar class="matero-toolbar" color="primary">
  <app-branding></app-branding><!-- *ngIf="showBranding"-->
  <!-- <span fxFlex></span> -->


  <mat-nav-list fxFlex fxLayout="row" fxLayoutAlign="center center" class="matero-branding-name list-horizontal mat-app-color">
    <a mat-list-item routerLink="{{link.route}}" *ngFor="let link of routesTemp" style="color: white;"> {{ link.name }} </a>
 </mat-nav-list>





  <app-search-button></app-search-button>
  
  <app-language></app-language>
  <app-theme></app-theme>
 
  <button mat-stroked-button color="accent" class="matero-toolbar-button" fxHide.lt-sm routerLink="/auth/login">
    <mat-icon>lock_open</mat-icon>
    {{'register.signin' | translate}}
  </button>
  <button mat-icon-button [routerLink]="['/auth/login']" fxHide.gt-xs fxHide.gt-sm>
    <mat-icon>lock_open</mat-icon>
  </button>

</mat-toolbar>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MadrugadaComponent } from './madrugada.component';

@NgModule({
  declarations: [MadrugadaComponent],
  imports: [
    CommonModule
  ],
  exports: [MadrugadaComponent]
})
export class MadrugadaModule { }

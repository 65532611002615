import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { routeAnimations, ROUTE_ANIMATIONS_ELEMENTS } from '@core/animations/route.animations';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-overlay',
  templateUrl: './search-overlay.component.html',
  styleUrls: ['./search-overlay.component.scss'],
  animations: [routeAnimations],
  encapsulation: ViewEncapsulation.None
})
export class SearchOverlayComponent implements OnInit, OnDestroy {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  private searchText: string;
  searchResults: any[]; subsSearch: Subscription;

  constructor(
    private router: Router,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.subsSearch) {
      this.subsSearch.unsubscribe();
    }
  }

  get search(): string {
    return this.searchText;
  }
  // This setter is called everytime the value in the search text box changes
  set search(value: string) {
    this.searchText = value;
    if (value.length > 1) {
      // this.subsSearch = this.clientesService.pesquisarCliente(value.toLowerCase()).subscribe((searchResults: any) => {
      //   if (searchResults) {
      //     this.searchResults = searchResults;
      //   }
      // });
      console.log('Pesquisa', value);
    } else {
      this.searchResults = [];
    }
  }

  off() {
    document.getElementById('searchOverlayy').classList.remove('showOverlayy');
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PublicRoutingModule } from './public-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { AngsModule } from '@shared/angs.module';
import { SnackModule } from '@shared/components/snack/snack.module';

import { ContainerModule } from 'src/app/layout/container/container.module';

const modules = [
  ContainerModule,
  AngsModule,
  TranslateModule,
  SnackModule,
];

@NgModule({
  declarations: [],
  imports: [
    modules,
    PublicRoutingModule
  ],
  exports: [
    modules
  ]
})
export class PublicModule { }

<button mat-icon-button fxFlexOrder="6" [matMenuTriggerFor]="matMenuTemas">
  <mat-icon>palette</mat-icon>
</button>

<mat-menu #matMenuTemas="matMenu" xPosition="before" [overlapTrigger]="true">
  <div fxLayout="column" *ngFor="let item of itemsTheme" class="matMenu">
    <div *ngIf="item.active"  class="cardTema" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
      <button mat-stroked-button  (click)="onClickTheme(item.name, false)" [style.background-color]="item.color">
        <mat-icon class="lampada">lightbulb</mat-icon>
        <mat-icon class="ehSelecTeco" *ngIf="item.selected && (item.dark === false)">check</mat-icon>
      </button>

      <button mat-stroked-button (click)="onClickTheme(item.name, true)" [style.background-color]="item.color">
        <mat-icon class="lampadaDark">lightbulb</mat-icon>
        <mat-icon class="ehSelecTeco" *ngIf="item.selected && (item.dark === true)">check</mat-icon>
      </button>
    </div>
  </div>
</mat-menu>
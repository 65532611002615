import { Injectable } from '@angular/core';
import { Notify } from '@models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor() { }

  showTopMenuNotifications(): Observable<Notify[]> {
    const notifications: BehaviorSubject<Notify[]> = new BehaviorSubject<Notify[]>([]);
    const messages: Notify[] =
    [
      {message: 'Notificação 1', type: 'info'},
      {message: 'Esteja Alerta!', type: 'warning'},
      {message: 'Você Recebeu um Arquivo', type: 'folder_special'},
      {message: 'Houve Algum Erro', type: 'error'},
      {message: 'Notícia Boa', type: 'notifications_active'},
      {message: 'Nova Solicitação de Amizade!', type: 'people'}
    ];
    notifications.next(messages);
    return notifications.asObservable();
  }
}

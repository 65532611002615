import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TardeComponent } from './tarde.component';

@NgModule({
  declarations: [TardeComponent],
  imports: [
    CommonModule
  ],
  exports:[TardeComponent]
})
export class TardeModule { }

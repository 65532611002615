import { SnackBarData } from '@models';
import { Component, Inject, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-snack',
  templateUrl: './snack.component.html',
  styleUrls: ['./snack.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SnackComponent implements OnInit, OnDestroy {
  theme: string; subTheme: Subscription;
  constructor(
    public snackBar: MatSnackBar,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  dismiss() {
    this.snackBar.dismiss();
  }

  confirm() {
    this.snackBar._openedSnackBarRef.dismissWithAction();
  }

  // action(event: Event) {
  //   event.stopPropagation();
  //   this.undoString = 'undid';
  //   this.toastPackage.triggerAction();
  //   return false;
  // }
}

import { AngsModule } from './../../shared/angs.module';
import { NgModule } from '@angular/core';
import { BrandingComponent } from './branding.component';

@NgModule({
  declarations: [BrandingComponent],
  imports: [AngsModule],
  exports: [BrandingComponent]
})
export class BrandingModule { }

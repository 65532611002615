import { Component, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'src/app/core/services/bootstrap/settings.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent {
  // @Output() matTriggerLanguage: any;

  langs = {
    'pt-BR': 'Português',
    es: 'Espanhol',
    'en-US': 'English'
  };

  constructor(private translate: TranslateService, private settings: SettingsService) {
    translate.addLangs(['pt-BR', 'es', 'en-US']);
    translate.setDefaultLang('pt-BR');

    const browserLang = navigator.language;
    translate.use(browserLang.match(/pt-BR|es|en-US/) ? browserLang : 'pt-BR');
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.settings.setLanguage(language);
  }
}

import { NgModule } from '@angular/core';
import { ManhaComponent } from './manha.component';



@NgModule({
  declarations: [ManhaComponent],
  imports: [
    
  ],
  exports: [ManhaComponent]
})
export class ManhaModule { }

import { NgModule } from '@angular/core';
import { AngsModule } from '@shared/angs.module';
import { SnackComponent } from './snack.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackService } from '@core/services/bootstrap';


@NgModule({
  declarations: [SnackComponent],
  imports: [
    AngsModule, TranslateModule,

    MatIconModule, MatButtonModule, MatSnackBarModule
  ],
  exports: [SnackComponent],
  providers: [SnackService]
})
export class SnackModule { }

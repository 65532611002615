import { NgModule } from '@angular/core';
import { AuthGuard } from '@core/guard/auth.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes =
[
  {
    path: '', redirectTo: 'public', pathMatch: 'full'
  },
  {
    path: `home`,
    loadChildren: () => import(`./stack/home/home.module`).then(m => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: `admin`, loadChildren: () => import(`./stack/admin/admin.module`).then(m => m.AdminModule),
    canActivate: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: 'ADMIN',
        redirectTo: '/home',
      },
    }
  },
  {
    path: 'auth',
    loadChildren: () => import('./stack/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'public',
    loadChildren: () => import('./stack/public/public.module').then(m => m.PublicModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<mat-sidenav-container fxFlexFill class="mat-app-background fundo" id=container><!--class="casa_abandonada1"-->
  <mat-sidenav-content fxLayout="column" fxFlex class="mat-app-background fundo"> <!--class="rain"-->
    <app-manha      class="efeito"  *ngIf="fundoAtual === 'manha'"      ></app-manha>
    <app-tarde      class="efeito"  *ngIf="fundoAtual === 'tarde'"      ></app-tarde>
    <app-noite      class="efeito"  *ngIf="fundoAtual === 'noite'"      ></app-noite>
    <app-madrugada  class="efeito"  *ngIf="fundoAtual === 'madrugada'"  ></app-madrugada>

    <app-header-auth class="header"></app-header-auth>
    <main class="contentOutLet" fxFlex [@routeAnimations]="o.isActivated && o.activatedRoute.routeConfig.path" style="overflow: auto;">
      <router-outlet #o="outlet"></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
<app-search-overlay id="searchOverlayy"></app-search-overlay>
